import React, { useEffect, useRef } from 'react';
import './Footer2.css';
import FooterBtn from './FooterBtn/FooterBtn';

import logo from './footer.svg';
import { Link } from 'gatsby';

const Footer2 = () => {
    return (
        <div className="Footer2">
            <div className="footer2_header">
                <div className="footer2_header_col">
                    <Link
                        to="/contatti"
                        style={{
                            textDecoration: `none`,
                        }}>

                        <div className="footer2_title">Hai un progetto in mente?</div>
                        <div className="button-parliamone">

                            <a className="footer2_title footer2_orange_text">Parliamone.</a>
                        </div>
                    </Link>

                </div>

                <div className="footer2_header_col">
                    <img className="footer2_header_dx_logo" src={logo} alt="logo"></img>
                </div>
            </div>

            <div className="footer2_info" >
                <div className="footer2_info_col">
                    <div className="footer2_info_col_text1">ALFATAURI STUDIO - P.IVA 03666510791</div>
                    <a href="https://www.google.com/maps/dir/41.9113214,12.5412766/alfatauri+studio/@41.9103027,12.5379467,17z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x132f63c95103fe59:0x536e7ec5b23acc8f!2m2!1d12.5390284!2d41.9092692" target="_blank" className="footer-link footer-address">

                    <div className="footer2_info_col_text1">Via Renato Simoni 30</div>
                    <div className="footer2_info_col_text1">00157, Roma </div>
                    </a>
                </div>

                <div className="footer2_info_col">
                    <FooterBtn text="Home" link="/" />
                    <FooterBtn text="Servizi" link="/servizi" />
                    <FooterBtn text="Contatti" link="/contatti" />

                </div>

                <div className="footer2_info_col">
                        <FooterBtn text="Instagram" target="_blank" link="https://www.instagram.com/alfatauristudio/" />
                        <FooterBtn text="Linkedin" target="_blank" link="https://www.linkedin.com/company/alfatauri-studio/?originalSubdomain=it" />
                    <div className="hidden-text">Instagramm</div>
                </div>
            </div>

            <div className="footer2_info_mobile">
                <div className="footer2_info_mobile_row">
                    <div className="footer2_info_col">
                    <FooterBtn text="Home" link="/" />
                    <FooterBtn text="Servizi" link="/servizi" />
                    <FooterBtn text="Contatti" link="/contatti" />
                    </div>

                    <div className="footer2_info_col">
                        <FooterBtn target="_blank"  text="Instagram" link="https://www.instagram.com/alfatauristudio/" />
                        <FooterBtn target="_blank"  text="Linkedin" link="https://www.linkedin.com/company/alfatauri-studio/?originalSubdomain=it" />
                    </div>
                </div>

                <div className="footer2_info_mobile_row">
                    <div className="footer2_info_col">
                        <div className="footer2_info_col_text1">ALFATAURI STUDIO</div>
                        <div className="footer2_info_col_text1">P.IVA 03666510791</div>
                    </div>

                    <div className="footer2_info_col" >
                        <a href="https://www.google.com/maps/dir/41.9113214,12.5412766/alfatauri+studio/@41.9103027,12.5379467,17z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x132f63c95103fe59:0x536e7ec5b23acc8f!2m2!1d12.5390284!2d41.9092692" target="_blank" className="footer-link footer-address">

                        <div className="footer2_info_col_text1">Via Renato Simoni 30</div>
                        <div className="footer2_info_col_text1">00157, Roma </div>
                        </a>

                    </div>
                </div>
            </div>

            <div className="footer2_end">
                <div className="footer2_end_text">© All rights reserved 2021</div>
                <div className="footer2_end_text">
                    <a className="footer-link" href="/informativa-privacy/"><span className="footer2_end_text_link">Cookie Policy</span></a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a className="footer-link" href="/informativa-privacy/"><span className="footer2_end_text_link">Termini e condizioni</span></a>
                </div>
            </div>
        </div>
    );
}

export default Footer2;