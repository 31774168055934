import { navigate } from 'gatsby-link';
import React from 'react';
import './FooterBtn.css';

const FooterBtn = props => {
    return(
        <div className="FooterBtn" onClick={() => navigate(props.link)}>
            <div className="footer_btn_container">
                <div className="footer_btn_text">{props.text}</div>
                <div className="footer_btn_underline"></div>
            </div>
        </div>
    );
}

export default FooterBtn;